.upload-video-modal {
  .ant-row {
    border: 1px solid $color-alto;
    border-radius: 3px;
  }

  .ant-col {
    padding: 1em;
  }

  .ant-space {
    display: flex;
    justify-content: center;
  }

  &__label {
    color: $color-shamrock;
    font-size: 16px;
    font-weight: bold;

    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  .ant-upload {
    margin-right: 1em;
  }

  .ant-input-group-wrapper,
  .ant-input-affix-wrapper {
    width: unset;
  }

  .column-video {
    background-color: $color-silver;
  }
}
