.div-enabed-comment .ant-switch-checked {
    background-color: #3BCC80;
}

.seminar-setting-tabs .ant-tabs-nav-list {
    margin: auto;
    color: #3BCC80;
}
.seminar-setting-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
}

.seminar-setting-tabs .ant-tabs-ink-bar {
    background: unset;
}

/*.seminar-setting-tabs .ant-tabs-tab-btn {*/
/*    border: 1px solid black;*/
/*    padding: 10px 20px 10px 20px;*/
/*}*/
/*.seminar-setting-tabs .ant-tabs-nav-wrap {*/
/*    border-bottom: 1px solid black;*/
/*}*/
/*.seminar-setting-tabs .ant-tabs-tab {*/
/*    margin: 0;*/
/*}*/