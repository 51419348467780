.talk-list-modal .ant-modal-title{
  color: #3BCC80;
}

.talk-list-modal-2 .ant-modal-close-x {
  display: none !important;
}

.talk-list-modal .ant-modal-close-x {
  display: none !important;
}
.talk-list-modal-2 .ant-modal-footer {
  border-top: unset;
}

.talk-list-modal .ant-modal-footer {
  border-top: unset;
}

.talk-list-modal .ant-select-selector {
  background: #5E5E5E !important;
  color: white;
}

.talk-list-modal .ant-select-arrow .anticon.anticon-down.ant-select-suffix {
  color: white;
}

#talk-list {
  .ant-btn.button {
    min-width: unset;
  }
}