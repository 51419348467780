.upload-image-modal {
  .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 1px solid $color-alto;
    border-radius: 4px;

    > * {
      padding: 20px;
    }
  }

  .ant-descriptions {
    &-item-label {
      font-weight: bold;
      color: $color-shamrock;
    }

    &-item {
      padding-bottom: 8px;
    }

    .avatar-uploader,
    .ant-upload {
      width: 100%;
    }
  }

  .column-right {
    background-color: $color-silver;

    &__image-preview {
      img {
        max-width: 100%;
      }
    }
  }

  .ant-progress {
    padding: 30px 0;
  }

  &__action {
    display: flex;
    justify-content: center;
  }
}
