.message {
  &__subtitle {
    font-size: 24px;
    color: $color-shamrock;
    font-weight: 700;
  }

  .ant-row {
    margin: 1em 0;
  }

  &__content {
    &__label {
      padding: 0 1em 0 2px;
    }

    &__checkbox {
      .ant-btn {
        border: none;
        background-color: transparent;
        box-shadow: none;
      }
    }

    &__textarea {
      width: 100%;
      border-color: $color-alto;
      border-radius: 2px;
      padding: 4px 11px;

      &:focus {
        outline: none;
      }
    }

    &__upload {
      &__input {
        display: flex;
        margin-top: 1em;
        align-items: flex-start;

        .input {
          flex: 1;
        }

        input {
          width: calc(100% - 1em);
          padding: 4px 11px;
          border-radius: 2px;
        }

        .ant-btn {
          padding: 6px 11px;
          height: unset;
        }

        .message.message-error {
          display: block;
        }
      }
    }

    .input {
      &__max--length {
        span {
          display: none;
        }
      }
      .required {
        display: none;
      }
    }
  }

  &__column-time.gutter-row {
    display: flex;
    align-items: flex-start;

    .input-day {
      input {
        width: 50px;
      }
    }

    .input-time-send {
      margin-left: 1em;

      .ant-picker {
        margin: 0 5px;
      }
    }

    span {
      display: flex;
    }
  }

  &__action {
    &.ant-space {
      display: flex;
      justify-content: center;
    }
  }
}
