.tag-management {
  .ant-layout.ant-layout-has-sider {
    height: unset;
  }

  .ant-btn.button {
    min-width: unset;
  }

  aside {
    padding-left: 0;
    padding-right: 0;
    border-right: 1px solid $color-mercury;

    .ant-menu {
      .ant-menu-item {
        padding: 0 20px !important;
        color: $color-scorpion;
        &.ant-menu-item-selected {
          background-color: rgba(59, 204, 128, 0.2);
        }
        &:after {
          border-right-width: 5px;
          border-right-color: $color-shamrock;
        }
      }
    }

    .ant-layout-sider-children {
      > .ant-btn {
        margin: 0 20px;
      }
    }
  }

  main {
    background-color: $color-alabaster-approx;
    table {
      border: 1px solid $color-mercury;
    }
  }

  &__sider {
    padding: 24px 0 0;
    max-height: 900px;

    .ant-layout-sider-children {
      line-height: normal;
    }

    &__list {
      max-height: 730px;
      overflow: hidden auto;

      &.ant-menu {
        margin-top: 1em;
      }

      &__item {
        padding: 0 !important;
      }
    }
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
  }

  .anticon ~ span {
    display: inline-flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;

    .ant-space {
      position: absolute;
      right: 1em;
    }
  }

  .ant-layout-content {
    overflow: auto;
  }

  .button-clear[disabled] {
    background-color: $color-alto;

    &:hover {
      background-color: $color-alto;
    }
  }

  section {
    border: 1px solid $color-mercury;
    border-radius: 3px;
  }

  &__content {
    &__action {
      display: flex;
      justify-content: space-between;

      &__input {
        max-width: 300px;
      }
    }

    &__table {
      margin-top: 10px;
    }
  }
}

@media (max-width: $tablet_break-desktop) {
  .tag-management {
    .ant-layout-sider {
      flex: 0 0 300px !important;
      max-width: 300px !important;
      min-width: 300px !important;
      width: 300px !important;
    }

    .folder-name {
      max-width: 100px;
    }
  }
}
