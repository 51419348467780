.rich-menu {
  &__subtitle {
    padding: 1em 0;
  }
  &__template-used {
    border: 1px solid $color-mercury;
    padding: 1em;
    margin-left: 0 !important;
    margin-right: 0 !important;

    &__column {
      &__image {
        width: 300px;
        height: auto;
      }
    }
  }

  &__form {
    padding: 20px 0;

    &__search {
      border: 1px solid $color-mercury;
      padding: 0 8px;
      border-radius: 2px;
      line-height: 30px;
      flex-direction: row-reverse;
      display: flex;
      align-items: center;

      #search {
        border: none;
        padding: 0 8px;

        &:focus {
          outline: none;
        }
      }

      .anticon-search {
        color: $color-alto;
      }
    }

    &__image {
      width: 150px;
      height: auto;
    }

    &__select-status {
      min-width: 100px;
    }

    .ant-select-selector {
      padding: 4px 8px;
    }
  }
  .button-clear {
    background-color: transparent;
    color: $color-sunset-orange;
    min-width: unset;
    &:hover {
      background-color: transparent;
      color: $color-sunset-orange;
    }
    span {
      text-decoration: underline;
    }
  }
}
