.modal-action {
  .ant-layout.ant-layout-has-sider {
    height: unset;
  }
}

.modal-update {
  .ant-modal-body {
    padding: 24px 100px;
  }

  .ant-layout {
    border: 1px solid $color-mercury;
    padding: 0 !important;
    margin-top: 25px;
    border-radius: 3px;
    aside {
      border-right: 1px solid $color-mercury;
      .ant-menu {
        .ant-menu-item {
          color: $color-scorpion;
          padding: 0 10px !important;
          &:after {
            border-right-color: $color-shamrock;
          }
        }
      }
    }
  }
}

.modal-update-action {
  display: flex;
  flex-direction: column;

  &__label {
    width: 200px;
  }

  &__input {
    display: flex;
    flex: 1;
    padding: 2px 4px;
    border: 1px solid $color-alto;
    border-radius: 4px;

    input {
      width: 100%;
      border: none;
    }

    &__search {
      input {
        width: 100%;
        border: none;
        outline: none;
      }

      &:focus {
        outline: none;
        border: 1px solid $color-silver-chalice;
        padding: 4px;
        border-radius: 4px;
      }
    }

    &__list-tag {
      display: flex;
      // max-width: 200px;
    }
  }

  label {
    display: flex;
    align-items: center;
    margin: 1em 0;
  }

  [name='tagsSelected'] {
    margin: 0 1em 0 0;
  }

  .ant-modal-body {
    padding: 0 100px;
  }

  .ant-layout-content {
    height: 300px;
    overflow: auto;
  }

  &__button {
    text-align: center;

    .button-close {
      margin-right: 1em;
    }
  }
}
