.create-message-package {
  &__box {
    border: 2px solid;
    border-radius: 4px;
    padding: 1em;

    &__title {
      font-weight: 700;
    }

    &__text-bold {
      font-weight: 700;
    }

    &__button.ant-btn {
      margin-left: auto;
      display: block;
      width: 150px;
      color: $color-white;
      border: none;
    }
  }

  .box-left {
    border-color: $color-green-haze;
  }

  .box-right {
    border-color: $color-picton-blue;
  }

  .button-left {
    background-color: $color-green-haze;

    &:hover,
    &:active,
    &:focus {
      background-color: $color-green-haze;
      color: $color-white;
    }
  }

  .button-right {
    background-color: $color-picton-blue;

    &:hover,
    &:focus,
    &:active {
      background-color: $color-picton-blue;
      color: $color-white;
    }
  }
}

.update-message-package {
  &__subtitle {
    font-size: 24px;
    color: $color-shamrock;
    font-weight: 700;
  }

  &__form {
    .ant-row {
      margin: 1em 0;
    }

    .button {
      margin: 1em 0;
    }

    input,
    .ant-select {
      width: 100%;
      max-width: 300px;
    }
  }

  .ant-space {
    display: flex;
    justify-content: center;
  }

  .ant-btn.button {
    min-width: unset;
  }

  .message.message-error {
    display: block;
  }
}
