.list-user {
  span.list-user__filter__name {
    border-radius: 3px;
  }
  &__filter {
    display: flex;
    margin-bottom: 35px;

    &__name {
      width: 100%;
      max-width: 500px;
      margin-right: 1em;
    }

    &__status.ant-select {
      width: 100%;
      min-width: 150px;
      height: 100%;

      div.ant-select-selector {
        height: 100%;
      }

      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }
    }
  }

  &__table {
    padding-top: 24px;

    .ant-table-thead {
      .ant-table-cell {
        font-weight: 700;
      }
    }
  }

  .ant-btn {
    min-width: 120px;
  }

  .ant-tag {
    min-width: 90px;
    text-align: center;
    margin: 4px;
  }

  table {
    .ant-table-tbody {
      .ant-table-cell {
        h4 {
          font-size: 24px;
        }
        div {
          color: $color-scorpion;
        }
      }
    }
  }
}
