.button.button {
  border-radius: 3px;
  &-active {
    background-color: $color-shamrock;
    color: $color-white;
    opacity: 1;

    &:hover {
      background-color: $color-shamrock;
      color: $color-white;
      opacity: 0.5;
    }

    &:focus {
      background-color: $color-shamrock;
      color: $color-white;
    }

    .anticon-search {
      color: $color-white;
    }
  }

  &-inactive {
    background-color: $color-sunset-orange;
    color: $color-white;
    &:hover {
      background-color: $color-sunset-orange;
      color: $color-white;
      opacity: 0.5;
    }

    &:focus {
      background-color: $color-sunset-orange;
      color: $color-white;
    }
  }

  &-solid.ant-btn {
    border: 1px solid $color-shamrock;
    background-color: transparent;
    color: $color-shamrock;

    &:hover {
      background-color: $color-shamrock;
      color: $color-white;
    }

    &.dusty-gray {
      border-color: $color-dusty-gray;
      color: $color-dusty-gray;
      &:hover {
        background-color: $color-dusty-gray;
        color: $color-white;
      }
    }
  }
}

.ant-btn {
  &.button {
    border: none;
    min-width: 100px;

    &-close {
      background-color: $color-silver-chalice;
      color: $color-white;

      &:hover {
        background-color: $color-silver-chalice;
        color: $color-white;
      }
    }

    &-clear {
      color: $color-sunset-orange;
      min-width: unset;
      span {
        text-decoration: underline;
      }

      &:hover,
      &:focus {
        color: $color-sunset-orange;
        opacity: 0.5;
        background: transparent;
      }
    }

    &-save {
      background-color: $color-green-haze;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-green-haze;
        color: $color-white;
      }
    }

    &-delete {
      background-color: $color-sunset-orange;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-sunset-orange;
        color: $color-white;
      }
    }
  }
}

.ant-modal {
  .ant-modal-title {
    font-weight: 700;
    text-align: center;
    color: $color-shamrock;
    font-size: 24px;
  }
}

.message {
  &.message-error {
    color: $color-sunset-orange;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.label-input {
  font-weight: 700;
  position: absolute;
  left: -50%;
  top: 50%;
  transform: translate(50%, -50%);
}

.button {
  &.ant-btn-text[disabled],
  &.ant-btn-text[disabled]:hover,
  &.ant-btn-text[disabled]:focus,
  &.ant-btn-text[disabled]:active {
    background-color: $color-alto;
    color: $color-white;
    border-color: $color-white;
    opacity: 0.5;
  }
}

.input:focus,
input:focus {
  outline: none;
}

input {
  border: 1px solid $color-mercury;
  padding: 4px 11px;
  &::placeholder {
    color: $color-silver;
  }
}

.action-update-tag {
  .ant-layout-content {
    label {
      display: flex;
      align-items: center;

      input {
        width: unset;
        margin-right: 1em;
      }
    }
  }
}

.select {
  .ant-select-selection-item {
    color: $color-silver;
  }
}

table {
  .ant-table-thead {
    th.ant-table-cell {
      color: $color-shamrock;
      font-size: 16px;
      font-weight: 400;
      border-color: $color-mercury;
    }
  }
  .ant-table-tbody {
    td.ant-table-cell {
      font-size: 16px;
      color: $color-scorpion;
    }
  }
}

.ant-pagination {
  li {
    border-color: $color-shamrock;
    color: $color-shamrock;
    font-size: 14px;
    border-radius: 3px;
    .ant-pagination-item-link {
      border-color: $color-shamrock;
      color: $color-shamrock;
    }
    &.ant-pagination-disabled {
      opacity: 0.5;
    }
    &.ant-pagination-item-active {
      background-color: $color-shamrock;
      color: $color-white;
      border-color: $color-shamrock;
    }
    &:hover {
      background-color: $color-shamrock;
      color: $color-white;
    }
  }
}

span.ant-tag {
  background-color: $color-shamrock;
  font-size: 14px;
  color: $color-white;
  border: none;
  padding: 4px 8px;
  a {
    color: $color-white;
    text-decoration: none;
    &:hover {
      color: $color-white;
    }
  }

  .anticon-close {
    svg {
      fill: $color-white;
    }
  }
}

.title-content {
  font-size: 16px;
  color: $color-shamrock;
  font-weight: 700;
}

.action-update-tag {
  section {
    .ant-layout-sider-children {
      overflow-y: auto;
    }
  }
}

.folder-name {
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.message-error {
  font-size: 12px;
  padding: 4px 0;
}

div input[type='checkbox'] {
  margin: 0 4px 0 0;

  &:not(:first-child) {
    margin-left: 1em;
  }
}

.modal-tag {
  .input {
    display: flex;
    flex-direction: column;
  }

  .input__max--length {
    span {
      display: none;
    }

    input {
      width: 100%;
    }
  }

  .message-error {
    margin-right: auto;
  }
}

.anticon.anticon-search {
  color: $color-silver;
}

.collapse-custom.ant-collapse {
  border: none;
  background-color: transparent;
  margin-top: 10px;

  .ant-collapse-item {
    border-bottom: none;

    .ant-collapse-header {
      .anticon {
        top: 57%;
        transform: translateY(-50%);
        padding-top: 0;
        font-size: 20px;
        font-weight: bold;
        color: $color-shamrock;
      }
    }
  }

  .header-component {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-content {
      margin-left: 1em;
    }
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.modal-message-example {
  li:not(:first-child) {
    margin-top: 20px;
  }

  img {
    width: 80%;
    height: auto;
    margin-top: 10px;
  }
}

.text-ellipsis-width {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// button.ant-switch-checked {
//   background-color: $color-shamrock;
// }

// .ant-radio .ant-radio-inner::after {
//   background-color: $color-shamrock;
// }

// .ant-radio-wrapper-checked .ant-radio-checked .ant-radio-inner {
//   border-color: $color-shamrock;
// }

// .ant-radio-group {
//   .ant-radio-wrapper:hover .ant-radio,
//   .ant-radio:hover .ant-radio-inner,
//   .ant-radio-input:focus + .ant-radio-inner {
//     border-color: $color-shamrock;
//   }
// }
