.modal-template {
  &__action.ant-space {
    display: flex;
    justify-content: center;
  }

  .ant-col {
    margin-bottom: 15px;
    label {
      height: auto;
      padding: 0;
      background: transparent !important;
      border: none;
      color: transparent;
      overflow: hidden;
      &.ant-radio-button-wrapper-checked {
        box-shadow: 0 0 0px 2px $color-valencia !important;
      }
    }
  }
}
