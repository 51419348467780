.modal-tag {
  .ant-modal-body {
    text-align: center;
  }

  .message-error {
    float: left;
  }

  .ant-space {
    margin-top: 2em;
  }

  .button-save[disabled] {
    background-color: $color-alto;

    &:hover {
      background-color: $color-alto;
    }
  }
}
