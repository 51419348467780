.conversion-form {
  .ant-space {
    display: flex;
    justify-content: center;
  }

  &__subtitle {
    font-weight: 700;
    font-size: 24px;
    color: $color-shamrock;
  }

  .ant-row {
    margin-top: 1em;
  }

  &__label {
    font-weight: 700;
    color: $color-shamrock;
  }

  &__input {
    width: 100%;
  }

  &__name {
    width: 100%;
    padding: 4px 11px;
    border: 1px solid $color-alto;
  }

  &__action {
    display: flex;

    .input {
      flex-grow: 1;
    }

    &__input {
      width: 100%;
      padding: 3px 11px;
    }

    &__switch {
      padding-top: 1em;
    }
  }

  .ant-input-search-button {
    background-color: transparent;
    border: 1px solid $color-shamrock;
    color: $color-shamrock;
    text-shadow: none;
    box-shadow: none;

    &:hover {
      background-color: $color-shamrock;
      border: 1px solid $color-shamrock;
    }
  }
}
