.create-rich-menu {
  .ant-row:not(.row-setting) {
    padding: 10px 0;
    flex-direction: column;
  }

  &__time.ant-row {
    flex-direction: row !important;
    padding: 10px !important;
    .ant-col {
      flex: unset;
      &:first-child {
        &:after {
          content: '-';
          margin: 0 10px;
          color: $color-mercury;
          font-size: 20px;
        }
      }
    }
  }
  &__subtitle {
    font-weight: 700;
    font-size: 24px;
    color: $color-shamrock;
  }

  &__display-settings {
    &__always-show {
      padding: 10px 0;
      display: flex;
      align-items: center;

      &__content {
        padding-right: 20px;
      }
    }

    &__label-field {
      font-weight: 700;
    }

    .title-content {
      margin-bottom: 5px;
      display: inline-block;
    }

    .input {
      input {
        border: 1px solid $color-mercury;
        margin-right: 10px;
        display: flex;
        flex: 1;
        line-height: 30px;
        padding: 0 10px;
        border-radius: 2px;

        &:focus {
          border: 1px solid $color-mercury;
          outline: none;
        }
      }

      &__max--length {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__settings {
    &__image-preview {
      width: 100%;
      max-width: 300px;
      object-fit: contain;

      &__template {
        width: 100%;
      }
    }

    .button-close {
      &:hover,
      &:focus {
        background-color: $color-scorpion;
        color: $color-white;
      }
    }

    .ant-upload {
      width: 100%;
    }

    .ant-btn {
      width: 100%;
      max-width: 300px;
      margin: 20px 0 0;
      &.ant-btn-text {
        font-size: 16px;
        border-radius: 3px;
        margin-right: 15px;
      }
      &.button-template {
        margin-bottom: 15px;
        border: 1px solid $color-mercury;
        color: $color-silver;
        font-size: 16px;
      }
    }

    .gutter-row {
      &:last-child {
        border-left: 1px solid $color-mercury;
        padding-left: 80px !important;
      }
    }
  }

  &__action.ant-space {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}
