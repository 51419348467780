@import '../sass/variables.scss';
@import '../sass/components.scss';
@import '../pages/ListUser/style.scss';
@import '../pages/ListUser/User/style.scss';
@import '../pages/ListUser/ModalEditUser/style.scss';
@import '../pages/ListUser/ModalUpdateTags/style.scss';
@import '../pages/RichMenuManagement/style.scss';
@import '../pages/RichMenuManagement/CreateRichMenu/style.scss';
@import '../pages/RichMenuManagement/CreateRichMenu/ModalTemplate/style.scss';
@import '../components/RichMenuItem/style.scss';
@import '../components/ModalUploadImage/style.scss';
@import '../pages/TagManagement/style.scss';
@import '../pages/TagManagement/Modal/style.scss';
@import '../pages/TagManagement/TagForm/style.scss';
@import '../pages/ActionManagement/style.scss';
@import '../pages/ActionManagement/ActionForm/style.scss';
@import '../components/SelectionAction/style.scss';
@import '../pages/ActionManagement/ExcuteActionModal/style.scss';
@import '../pages/Conversion/style.scss';
@import '../pages/Conversion/Form/style.scss';
@import '../pages/PersonalTalk/style';
@import '../components/SelectionMessagePackage/style.scss';
@import '../pages/MessagePackage/style.scss';
@import '../pages/MessagePackage/Create/style.scss';
@import '../pages/MessagePackage/Create/Message/style.scss';
@import '../pages/MessagePackage/Create/TagModal/style.scss';
@import '../components/UploadVideoModal/style.scss';
@import '../pages/TalkList/style.scss';
@import '../components/ModalUploadImageResizer/style.scss';
@import '../pages/SeminarSetting/style.css';

.bg-app {
  background-color: blue($color: #000000) !important;
  height: 100vh;
}

// LOGIN PAGE
#login-div {
  margin-top: 100px;
}

.login-header {
  height: 30vh;
  width: 100%;
  background: #3bcc80;
}

.login-logo {
  width: 300px;
  height: auto;
}

.line-webinar {
  font-family: Inter;
  font-size: 31px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.form-login-container {
  width: 21vw;
  height: 50vh;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.sign-in-title {
  opacity: 0.87;
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #000000;
  padding-top: 2vh;
}

.sign-in-wellcome {
  opacity: 0.6;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000000;
  margin-top: 3vh;
}

.form-login {
  margin-top: 4vh;
}

.form-login-input {
  width: 15vw !important;
  height: 5vh;
  margin-top: 2vh;
  border-radius: 4px;
  background-color: #f8f8f8 !important;
}

.button-login {
  margin-top: 5vh;
  height: 5vh;
  width: 9vw;
  border-color: #3bcc80 !important;
  border-radius: 4px !important;
  background-color: #3bcc80 !important;
}

.title {
  color: $color-shamrock;
  font-weight: 700;
  font-size: 32px;
}

.icon {
  .anticon {
    svg {
      fill: $color-silver;
    }
  }
}

.active {
  color: $color-limeade;
}

.inactive {
  color: $color-sunset-orange;
}

.ant-menu.ant-menu-light:not(.ant-menu-horizontal) {
  .ant-menu-item {
    &:hover,
    &:active {
      background-color: rgba(59, 204, 128, 0.2);

      .anticon {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  .ant-menu-item-selected {
    background-color: rgba(59, 204, 128, 0.2);
  }

  .anticon {
    color: rgba(0, 0, 0, 0.85);
  }
}
