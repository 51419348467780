.ant-modal {
  &.modal-edit-user {
    .label-row {
      font-weight: 700;
      display: flex;
      align-items: flex-start;
    }

    .ant-col {
      margin: 10px 0;
    }

    .input {
      input {
        width: 100%;
        border: 1px solid $color-alto;
        border-radius: 4px;
        padding: 4px 11px;

        &:focus {
          outline: none;
          border: 1px solid $color-silver-chalice;
        }
      }
    }

    .ant-picker {
      width: 100%;
    }

    .ant-select {
      width: 100%;

      &-selection-item {
        color: $color-black;
      }
    }
  }

  .modal-edit-user {
    &__action {
      display: flex;
      justify-content: center;
    }
  }
}
