.execute-action-modal {
  .ant-input-affix-wrapper {
    width: 50%;
    max-width: 300px;
  }

  .ant-table-wrapper {
    margin-top: 1em;
  }

  .ant-tag {
    margin: 0.2em;
  }

  &__action {
    text-align: center;

    .button-inactive {
      margin-right: 1em;
    }
  }

  .ant-input-affix-wrapper {
    display: flex;
    flex-direction: row-reverse;
    .ant-input-prefix {
      span {
        color: $color-silver;
      }
    }
  }
}
