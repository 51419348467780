.modal-update-action {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__tag-none {
    background-color: $color-white;
    width: 100%;
    border-bottom: 1px solid $color-alto;

    &.ant-radio-wrapper {
      padding: 16px 8px;
    }
  }
}

.modal-action {
  .ant-layout-sider {
    border: 1px solid $color-alto;
  }

  .ant-layout-sider-children {
    height: 100%;
    max-height: 400px;
    overflow-y: auto;
  }

  .ant-table-wrapper {
    .ant-table-cell {
      border: none;
    }
  }

  .ant-layout-content {
    background-color: transparent;
    border: 1px solid $color-alto;
  }

  .ant-menu-item {
    padding: 0 20px !important;
    color: $color-scorpion;

    &:hover {
      color: $color-scorpion;
    }

    span {
      max-width: 180px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .ant-menu-item.ant-menu-item-selected {
    background-color: rgba(59, 204, 128, 0.2) !important;

    &::after {
      border-right-width: 5px;
      border-right-color: $color-shamrock;
    }
  }

  .modal-update-tags__button {
    display: flex;
    justify-content: center;
  }
}
