.select-message-package {
  &__input {
    display: flex;
    border: 1px solid $color-alto;
    border-radius: 2px;
    padding: 4px 10px;

    .ant-input {
      border: none;
      padding: 0;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &__table {
    .button-choosing {
      border: none;
      box-shadow: none;
      background: transparent;

      &:hover,
      &:focus,
      &:active {
        background: transparent;
        color: $color-black;
      }
    }
  }
}
