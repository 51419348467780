.personal-talk {
  .ant-layout.ant-layout-has-sider {
    height: unset;
  }

  .ant-btn.button {
    min-width: unset;
  }

  &__sider {
    padding: 24px;
    max-height: 900px;
    overflow: hidden;

    .ant-layout-sider-children {
      line-height: normal;
      overflow: hidden;
    }

    &__list {
      overflow: hidden auto;
      height: 100%;

      &.ant-menu {
        margin-top: 1em;
      }

      &__item {
        padding: 0 !important;
      }
    }
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
  }

  .anticon ~ span {
    display: inline-flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;

    .ant-space {
      position: absolute;
      right: 1em;
    }
  }

  .ant-layout-content {
    overflow: auto;
  }

  .button-clear[disabled] {
    background-color: $color-alto;

    &:hover {
      background-color: $color-alto;
    }
  }

  &__content {
    &__action {
      display: flex;
      justify-content: space-between;

      &__input {
        max-width: 300px;
      }
    }

    &__table {
      margin-top: 10px;
    }
  }

  &__overme_100 {
    width: 100%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
  }

  &__overme {
    width: 110%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
  }
}
