.create-action {
  .ant-space {
    display: flex;
    justify-content: center;
  }

  .title-content {
    padding: 16px 0 8px 0;
  }

  form > .ant-row {
    display: flex;
    flex-direction: column;
  }

  &__folder-action {
    .ant-select {
      width: 300px;
    }
  }

  &__subtitle {
    font-size: 24px;
    color: $color-shamrock;
    font-weight: 700;
  }

  &__name {
    input {
      width: 300px;
      padding-left: 14px;
    }

    label {
      position: absolute;
      left: 15px;
      top: 4px;
      color: $color-silver-chalice;
    }
  }

  &__action {
    .ant-space {
      justify-content: start;
    }
  }

  .modal-update-tags__action {
    flex-wrap: wrap;

    .title-content {
      padding-top: 0;
      margin-bottom: 0;
    }

    .message-error {
      width: 100%;
      margin-left: 200px;
    }
  }

  .ant-select-selection-item {
    color: $color-black;
  }

  .ant-menu-item {
    &:hover {
      color: $color-black;
    }

    &-selected {
      color: $color-black;
    }
  }

  &__section-send-message {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.4em;
      margin: 1em 0;
      border-radius: 2px;
      color: $color-white;
    }

    &__folder {
      &.ant-row {
        flex-direction: column;
      }

      margin-top: 1em;

      .ant-select {
        width: 300px;
      }

      .ant-picker {
        margin-top: 1em;
      }

      .ant-space {
        justify-content: start;
      }

      &__checkbox {
        display: flex;
        align-items: center;

        .ant-checkbox-wrapper {
          padding-left: 0;
        }

        .ant-btn {
          border: none;
          padding-left: 2em;
        }

        input[type='checkbox'] {
          margin: 0;

          &:not(:first-child) {
            margin-left: 10px;
          }
        }

        &__label {
          margin: 0 0 0 5px;
        }
      }

      &__message-require {
        margin-top: 1em;
      }

      &__upload {
        margin-top: 1em;

        .ant-space {
          margin-top: 0.3em;

          .ant-space-item:first-child {
            flex: 1;
          }
        }

        input {
          width: 100%;
          border: 1px solid $color-alto;
          padding: 2px 11px;

          &:focus {
            outline: none;
          }
        }

        input[disabled] {
          cursor: not-allowed;
        }
      }

      .input__max--length {
        span {
          display: none;
        }
      }

      &__textarea {
        width: 100%;
        border: 1px solid $color-alto;
        padding: 4px 11px;

        &:focus {
          outline: none;
        }
      }
    }
  }

  &__section-package-operation {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.4em;
      margin: 1em 0;
      border-radius: 2px;
      color: $color-white;
    }

    &__package {
      margin-top: 1em;
      flex-direction: column !important;

      input {
        width: 100%;
        padding: 2px 11px;
      }

      .select {
        margin: 1em 0;
      }
    }

    .modal-update-tags__action__input {
      overflow-x: auto;
    }

    .action-update-tag {
      .ant-menu {
        .ant-menu-item {
          color: $color-scorpion;
        }
        .ant-menu-item-selected {
          background: rgba(59, 204, 128, 0.2);
          &:after {
            border-right-color: $color-shamrock;
          }
        }
      }
    }
  }

  .message-error {
    display: block;
  }
}

.edit-action {
  &__section-send-message {
    &__folder {
      &__checkbox {
        display: flex;
        align-items: center;

        .ant-checkbox-wrapper {
          padding-left: 0;
        }

        .ant-btn {
          border: none;
          padding-left: 2em;
        }

        input[type='checkbox'] {
          margin: 0;

          &:not(:first-child) {
            margin-left: 10px;
          }
        }

        &__label {
          margin: 0 0 0 5px;
        }
      }
    }
  }
}
