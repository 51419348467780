.rich-menu-item {
  .select {
    max-width: 400px;
    width: 100%;
    margin: auto;
  }

  .ant-select {
    width: 100%;
  }

  &__text {
    max-width: 400px;
    width: 100%;
    margin: auto;
    margin-top: 10px;

    &__select-action {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .ant-btn {
        margin-top: 0;
        width: 100px;
      }
    }
  }

  &__link {
    max-width: 400px;
    width: 100%;
    margin: auto;
    margin-top: 10px;
  }

  .message-error {
    max-width: 400px;
    width: 100%;
    margin: auto;
    margin-top: 10px;
  }

  &.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    font-size: 24px;
    font-weight: bold;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
  }

  .ant-collapse-header {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    width: 100px;
    justify-content: space-between;
    align-items: center;
  }
}
