.user-details {
  .block-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;

    &__title {
      font-weight: 700;
      font-size: 1.5em;
    }

    &__button {
      font-weight: 700;
      color: $color-white;
      background-color: $color-sunset-orange;
      border-radius: 3px;

      &:hover {
        color: $color-white;
        background-color: $color-sunset-orange;
        opacity: 0.5;
      }

      &:focus {
        color: $color-white;
        background-color: $color-sunset-orange;
      }
    }
  }

  .label-row {
    span {
      font-weight: 700;
    }
  }

  .ant-col {
    padding: 20px 0;
  }

  .section-seminar {
    &__content {
      img {
        width: 200px;
        height: auto;
      }
    }
  }

  .list-tag {
    padding: 30px 0 0;
  }

  .section-seminar__content {
    color: $color-scorpion;
    font-size: 16px;
  }

  .section-user__content {
    word-wrap: break-word;
  }
}
