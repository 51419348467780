.message-package {
  &__top {
    display: flex;
    justify-content: space-between;

    &__input {
      max-width: 300px;
      width: 100%;
    }
  }

  &__table {
    margin-top: 1em;
  }

  .ant-btn.button {
    min-width: unset;
  }
}

.modal-message-detail {
  padding: 30px;
  border: 1px solid $color-alto;
  border-radius: 4px;

  &__button {
    text-align: center;
  }
}

.list-subscriber-modal {
  .anticon {
    color: $color-alto;
  }

  .ant-table-wrapper {
    margin-top: 10px;
  }

  .button {
    display: block;
    margin: auto;
  }
}
