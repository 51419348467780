html .ant-layout {
  background-color: #ffffff;
}
.ant-layout-header {
  height: 70px;
  padding: 0 3vh;
  background: #3bcc80;
  color: #fff;
}
.ant-layout.ant-layout-has-sider {
  height: 93vh;
}
.ant-layout-footer {
  padding: unset;
  background: white;
  color: #fff;
}

.code-box-demo {
  text-align: center;
}

[data-theme='dark'] .ant-layout-header {
  background: #3bcc80;
}

.ant-layout-sider {
  background: #ffffff;
  color: #fff;
  line-height: 120px;
}
[data-theme='dark'] .ant-layout-sider {
  background: #3bcc80;
}
.ant-layout-content {
  background: rgba(0, 0, 0, 0.1);
  color: #000000;
  overflow: hidden;
}
.menu-icon {
  color: white;
}

.anticon {
  vertical-align: 0.125em;
}

.logo {
  width: 130px;
  height: auto;
  margin-left: 40px;
}

.event-loading {
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  cursor: not-allowed;
  z-index: 99999;
}
.event-loading > div {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.event-loading.loading .ant-spin:after {
  content: attr(data-text);
  display: inline-block;
  font-size: 14px;
  animation: loading 2s infinite;
}

.menu--main {
  height: calc(100vh - 70px);
  overflow: auto;
}
