$color-eucalyptus: #28a745;
$color-white: #fff;
$color-salmon: #ff7875;
$color-marzipan: #f8d49d;
$color-aqua-forest: #5aa469;
$color-valencia: #d2423a;
$color-green-haze: #07af57;
$color-silver-chalice: #a5a5a5;
$color-alto: #cfcfcf;
$color-sunset-orange: #ff4d4f;
$color-black: #000;
$color-picton-blue: #2db7f5;
$color-pizazz: #ff9100;
$color-shamrock: #3bcc80;
$color-silver: #bcbcbc;
$color-limeade: #24b500;
$color-sunset-orange: #ff5349;
$color-alabaster: #fafafa;
$color-alabaster-approx: #fbfbfb;
$color-mercury: #e6e6e6;
$color-scorpion: #5e5e5e;
$color-dusty-gray: #9c9c9c;

$tablet_break-desktop: 1366px;
$tablet_break-big: 1024px;
$tablet_break-point: 991px;
$mobile_break-point: 767px;

.text-pizazz {
  color: $color-pizazz;
}

.text-shamrock {
  color: $color-shamrock;
}

.hidden {
  display: none;
}
