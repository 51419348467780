.create-tag {
  .ant-space {
    display: flex;
    justify-content: center;
  }

  &__folder-tag {
    margin-top: 1em;

    .ant-select {
      width: 300px;
    }
  }

  .ant-select-selection-item {
    color: $color-black;
  }

  &__subtitle {
    font-size: 24px;
    color: $color-shamrock;
    font-weight: 700;
  }

  &__name {
    margin-top: 1em;

    input {
      width: 300px;
      padding: 4px 11px;
      border: 1px solid $color-alto;
    }

    label {
      position: absolute;
      left: 15px;
      top: 4px;
      color: $color-silver-chalice;
    }
  }

  &__action {
    margin-top: 1em;

    .ant-space {
      justify-content: start;
    }
  }

  .message {
    display: block;
  }

  &__section-send-message {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1em 0;
      border-radius: 2px;
      color: $color-white;
    }

    &__folder {
      margin-top: 1em;

      &.ant-row {
        flex-direction: column;
        .ant-col-6 {
          margin-bottom: 5px;
        }
      }

      .ant-select {
        width: 300px;
      }

      .ant-picker {
        margin-top: 1em;
      }

      .ant-space {
        justify-content: start;
      }

      &__checkbox {
        display: flex;
        align-items: center;

        .ant-checkbox-wrapper {
          padding-left: 0;
        }

        .ant-btn {
          border: none;
          padding-left: 2em;
        }
      }

      &__text {
        width: 100%;
        border: 1px solid $color-alto;
        padding: 4px 11px;

        &:focus {
          outline: none;
        }
      }

      .input {
        span {
          display: none;
        }
      }

      &__message-require {
        margin-top: 1em;
      }

      &__upload {
        margin-top: 1em;

        .ant-space {
          margin-top: 0.3em;

          .ant-space-item:first-child {
            flex: 1;
          }
        }

        input {
          width: 100%;
          border: 1px solid $color-alto;
          padding: 2px 11px;

          &:focus {
            outline: none;
          }
        }

        input[disabled] {
          cursor: not-allowed;
        }
      }
    }
  }

  &__section-package-operation {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1em 0;
      border-radius: 2px;
      color: $color-white;
    }

    &__package {
      margin-top: 1em;
      flex-direction: column !important;

      input {
        width: 100%;
        padding: 2px 11px;
      }
    }
  }

  form {
    > .ant-row {
      flex-direction: column;
      .title-content {
        margin-bottom: 10px;
      }

      input {
        border: 1px solid $color-mercury;
        padding: 4px 11px;
      }
    }
  }
}

.modal-shorten-url {
  &__input-url {
    display: flex;
    align-items: center;

    &__label {
      flex: 1 1 40%;
      max-width: 40%;
      margin-bottom: 0;
    }

    &__input {
      flex: 1 1 60%;
      max-width: 60%;
    }
  }

  &__description {
    margin-top: 1em;
  }

  .ant-btn {
    margin: auto;
    margin-top: 1em;
    display: block;
  }
}

.edit-tag {
  &__section-send-message {
    &__folder {
      &__checkbox {
        display: flex;
        align-items: center;

        .ant-checkbox-wrapper {
          padding-left: 0;
        }

        .ant-btn {
          border: none;
          padding-left: 2em;
        }

        input[type='checkbox'] {
          margin: 0;

          &:not(:first-child) {
            margin-left: 10px;
          }
        }

        &__label {
          margin: 0 0 0 5px;
        }
      }
    }
  }
}
