.modal-update-tags {
  section.ant-layout {
    height: 400px;
    border: 1px solid $color-alto;
  }

  .ant-layout-sider-children {
    overflow-y: auto;
  }

  &__action {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1em;

    &__label {
      width: 200px;
    }

    &__input {
      display: flex;
      flex: 1;
      padding: 2px 4px;
      border: 1px solid $color-alto;
      overflow-x: auto;
      border-radius: 4px;

      &__search {
        width: 100%;
        min-width: 200px;

        input {
          width: 100%;
          border: none;
          outline: none;
        }

        &:focus {
          outline: none;
          border: 1px solid $color-silver-chalice;
          padding: 4px;
          border-radius: 4px;
        }
      }

      &__list-tag {
        display: flex;
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    margin: 1em 0;

    &.title-content {
      margin: 0;
    }
  }

  [name='tagsSelected'] {
    margin: 0 1em 0 0;
  }

  [type='radio'] {
    margin: 0 1em 0 0;
  }

  .ant-menu {
    .ant-menu-item {
      &.ant-menu-item-selected {
        background-color: rgba(59, 204, 128, 0.2);
        color: $color-scorpion;

        &::after {
          border-right-color: $color-shamrock;
        }
      }
    }
  }

  .ant-layout-content {
    overflow: auto;
    background-color: transparent;
  }

  .ant-empty {
    margin: auto;
  }

  &__button {
    text-align: center;

    .button-solid {
      margin-right: 20px;
    }

    .button-close {
      margin-right: 1em;
    }
  }
}
