html {
  font-size: 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.no-wrap {
  white-space: nowrap !important;
}

.align-middle {
  vertical-align: middle;
}

.table-borderless td,
.table-borderless th {
  border: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Organizer List Page */
.inner-addon {
  position: relative;
}

/* style icon */
.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

/* align icon */
.left-addon .glyphicon {
  left: 0px;
}
.right-addon .glyphicon {
  right: 0px;
}

/* add padding  */
.left-addon input {
  padding-left: 30px;
}
.right-addon input {
  padding-right: 30px;
}
#organizer-list-table th {
  background-color: #80808024;
}

.create-btn {
  min-width: 70px;
  background-color: #6ed37d !important;
  color: black !important;
}

.edit-btn,
.create-organizer-btn {
  min-width: 150px;
  background-color: #6ed37d !important;
  color: black !important;
}

/* Organizer Detail Page */
.cancel-btn {
  min-width: 150px;
  background-color: #e6e6e6 !important;
  color: black !important;
}

ul.pagination {
  justify-content: center !important;
}

.error-div {
  max-height: 35px !important;
  margin-top: 3px;
}

.error-div.alert {
  padding: 0.3rem 0.1rem 0.8rem 1rem;
}

/* LAYOUT */
.logo-icon {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rosybrown;
  display: flex;
}
.logo-icon .app-name {
  font-size: 35px;
  text-align: center;
}

.nav-menu {
  background: #7b534e !important;
  min-height: 100vh;
  height: 100vh;
  color: white;
}
.nav-menu a {
  color: white;
}
.menu-item {
  padding: 12px;
  font-size: 20px;
}
.menu-item a:hover,
a:checked {
  text-decoration: none;
  font-weight: bold;
  color: white;
}

.ant-menu-item {
  padding-left: 24px;
  opacity: 0.7;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #0f0f0f;
}

.main-content > .header {
  min-height: 4rem;
  background-color: #4a90e2;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.content {
  overflow-y: scroll;
  height: calc(100vh - 70px);
  padding: 32px 16px;
  border-radius: 4px;
  background-color: #ffffff;
}

.container {
  padding-bottom: 15px;
}

.anticon-user {
  display: flex !important;
  justify-content: center !important;
}

.avatar {
  left: 78%;
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%);
  border-color: white !important;
}

.logout > span {
  display: inline-flex !important;
}

.ant-steps-item-icon .anticon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.ant-input-number {
  width: 100% !important;
}
/* CREATE ORGANIZER PAGE */
