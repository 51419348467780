.upload-image-resize-modal {
  .select {
    width: 100%;
  }

  .ant-select {
    width: 100%;
  }

  .ant-select-selection-item {
    color: $color-scorpion;
  }

  .column-right__image-preview {
    height: 400px;
    overflow: auto;

    .image {
      object-fit: cover;
    }
  }

  .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 1px solid $color-alto;
    border-radius: 4px;

    > * {
      padding: 20px;
    }
  }

  .ant-descriptions {
    &-item-label {
      font-weight: bold;
    }

    &-item {
      padding-bottom: 8px;

      &-label {
        color: $color-shamrock;
      }

      .avatar-uploader,
      .ant-upload {
        width: 100%;
      }

      .ant-select-arrow {
        color: $color-scorpion;
      }
    }

    &-row > td {
      padding-bottom: 16px;
    }
  }

  .column-right {
    background-color: $color-silver;
  }

  .image-size-nano {
    width: 130px;
    height: 85px;
  }

  .image-size-micro {
    width: 170px;
    height: 115px;
  }

  .image-size-kilo {
    width: 280px;
    height: 190px;
  }

  .image-size-mega {
    width: 320px;
    height: 215px;
  }

  .image-size-giga {
    width: 520px;
    height: 345px;
  }

  &__action {
    display: flex;
    justify-content: center;
  }
}
