.conversion {
  .ant-layout.ant-layout-has-sider {
    height: unset;
  }

  .ant-btn.button {
    min-width: unset;
  }

  .ant-layout-content {
    overflow: auto;
  }

  aside {
    border: 1px solid $color-alto;

    .ant-menu .ant-menu-item {
      padding: 0 20px !important;
      color: $color-scorpion;
    }

    .ant-menu .ant-menu-item-selected {
      background-color: rgba(59, 204, 128, 0.2);
      color: $color-scorpion;
    }
  }

  &__sider {
    padding: 24px 0 0;
    max-height: 900px;

    .ant-layout-sider-children {
      line-height: normal;
      overflow: hidden;

      .button-active {
        margin-left: 1em;
      }
    }

    &__list {
      overflow: hidden auto;
      max-height: 730px;

      &.ant-menu {
        margin-top: 1em;
      }

      &__item {
        padding: 0 !important;
      }
    }
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
  }

  .anticon ~ span {
    display: inline-flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;

    .ant-space {
      position: absolute;
      right: 1em;
    }
  }

  .ant-layout-content {
    overflow: auto;
    border: 1px solid $color-alto;
  }

  main {
    background-color: transparent;

    table {
      border: 1px solid $color-alto;
    }
  }

  .ant-input-search-button {
    background-color: transparent;
    border: 1px solid $color-shamrock;
    color: $color-shamrock;
    text-shadow: none;
    box-shadow: none;

    &:hover {
      background-color: $color-shamrock;
      border: 1px solid $color-shamrock;
      color: $color-white;
    }
  }

  .button-clear[disabled] {
    background-color: $color-alto;

    &:hover {
      background-color: $color-alto;
    }
  }

  &__content {
    &__action {
      display: flex;
      justify-content: space-between;

      &__input {
        max-width: 300px;
      }
    }

    &__table {
      margin-top: 10px;
    }
  }

  .overme {
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
